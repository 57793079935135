import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StarRating from "../components/StarRating.js";
import ExperienceCalc from "../components/ExperienceCalc.js";
import "../App.css";

function About() {
  return (
    <Container>
      <div className="resume">
        <div className="header">
          <h1>Lauren Dransfield <span style={{ fontSize: "0.5em" }}>BEng CEng MIMechE</span></h1>
        </div>

        <Container fluid>
          <Row>
            <p>
            Chartered Simulation and Analysis Engineer with <ExperienceCalc />+ years of experience in fluid system modelling. Proven expertise in CFD and 1D simulation for performance optimisation and design validation across complex engineering systems. Skilled in methodology development, technical leadership, and mentoring teams to enhance simulation capabilities and efficiency. Passionate about numerical analysis, workflow automation, and integrating simulation tools to improve efficiency, accuracy, and interoperability.
            </p>
          </Row>
          <Row>
            <Col md={{ order: "last", span: 8 }}>
              <h2>Professional experience</h2>
              <h4>Babcock International Group</h4>
              <h5>
                Principal Fluid Analysis Subject Matter Expert
                <span style={{ float: "right" }}>July 2024 - present</span>
              </h5>
              <p>Lead and coordinate simulation activities, managing task allocation and scheduling.</p>
              <p>Develop and implement best practices for simulation methodologies and tools.</p>
              <p>Drive method development and innovation to enhance analysis capabilities.</p>
              <p>Conduct varied simulations and analyses to drive product development and ensure qualification.</p>
              <p>Train and mentor engineers in simulation techniques and support their career development.</p>
              <h5>
                Senior Fluid Analyst
                <span style={{ float: "right" }}>June 2018 - July 2024</span>
              </h5>
              <p>Led simulation projects, defining budgets, timelines, and technical strategies.</p>
              <p>Developed custom AMESim components in C for enhanced 1D hydraulic modelling.</p>
              <p>Conducted CFD of submerged bodies and internal flows, optimizing performance.</p>
              <p>Applied FSI methodologies to model underwater explosion effects.</p>
              <p>Used in-house code to optimize hydraulic and pneumatic systems.</p>
              <p>Analysed test data for qualification compliance.</p>
              <h4>Delphi Technologies</h4>
              <h5>
                Simulation and Analysis Engineer
                <span style={{ float: "right" }}>October 2015 - June 2018</span>
              </h5>
              <p>Applied statistical analyses and Six Sigma to 1D AMESim models for first-time pass rate predictions.</p>
              <p>Led future product performance analysis, overseeing simulation-driven design optimization.</p>
              <p>Established transient electromagnetic FEA methodologies in ANSYS Maxwell.</p>
              <p>Ran test rigs to assess product performance repeatability.</p>
              <p>Served as primary technical contact for overseas teams.</p>
              <p>Conducted IATF-16949 internal audits to ensure compliance.</p>
              <h5>
                Graduate Simulation and Analysis Engineer
                <span style={{ float: "right" }}>October 2012 - October 2015</span>
              </h5>
              <p>Developed fast empirical AMESim models for accurate sub-second simulations.</p>
              <p>Optimized product performance through 1D (AMESim), CFD (Fluent), and FEA (Maxwell).</p>
              <p>Validated electromagnetic simulations against test data.</p>
              <p>Automated workflows using Python and MATLAB, improving efficiency.</p>
              <h2>Education</h2>
              <h4>Brunel University of London</h4>
              <h5>
              BEng (Hons) Mechanical Engineering
                <span style={{ float: "right" }}>June 2012</span>
              </h5>
              <p>Dissertation investigated blood flow dynamics using CFD in 3D-reconstructed arterial geometries from CT scans. Applied advanced meshing techniques and turbulence modelling to analyse flow behaviour, aiding in the understanding of factors influencing vascular diseases.</p>
              <h4>Waddesdon C of E School</h4>
              <h5>
                GCE (A-Level)
                <span style={{ float: "right" }}>June 2009</span>
              </h5>
              <p>English Literature, Mathematics, Physics</p>
              <h5>
                GCE (AS-Level)
                <span style={{ float: "right" }}>June 2009</span>
              </h5>
              <p>Engineering, Art, General Studies</p>
              <h5>
                GCSE
                <span style={{ float: "right" }}>June 2007</span>
              </h5>
              <p>Mathematics, Statistics, Physics, Chemistry, Biology, English Language, English Literature, Art, Design Technology, Information Technology, History, Geography, Business Communication Systems, French, Religious Studies</p>
              <h2>Awards</h2>
              <p>Winner of Babcock International Group's 2021 hackathon, BAB.HACK, and category winner for Best Presentation.</p>
              <h2>Publications</h2>
              <p>
                A paper titled "<strong>Heavy Duty Fuel systems - Developing the Next Generation</strong>", written by myself and three colleagues and presented at "
                <a
                  href="https://events.imeche.org/docs/default-source/combustion-engines-and-fuels-events/c1409-fuel-systems-brochure_z_web_update_3"
                  target="_blank"
                  rel="noopener noreferrer"
                >Fuel Systems for IC Engines: Inject Your Ideas, Fuel Your Technology</a>" in March 2015. This paper discusses advancements in diesel common rail injectors, focusing on component miniaturisation and a unique 3-way valve for complex multiple injection capabilities.
              </p>
              <p>
                A patent titled "<strong>Fuel Injector</strong>", publication number "
                <a
                  href="https://worldwide.espacenet.com/patent/search/family/061131749/publication/GB2569627A?q=pn%3DGB2569627A"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GB2569627A
                </a>
                ", in December 2017, describing a novel plate valve which gives asymmetric throttling of flow into the nozzle control chamber of a fuel injector when filling and draining.
              </p>
              <h2>Personal life</h2>
              <p>Passionate about technology and hands-on engineering, developing web apps and programming microcontrollers (Raspberry Pi, Arduino) for automation and personal projects.</p>
              <p>Designed, 3D printed, and built a desktop CNC mill for creation of custom components.</p>
              <p>Active in competitive powerlifting and rugby, demonstrating commitment, discipline, and teamwork.</p>
            </Col>
            <Col md={{ order: "first", span: 4 }}>
              <h2>Technical skills</h2>
              <h5>Computational fluid dynamics</h5>
              <p>
                ANSYS Fluent <StarRating score={4} />
              </p>
              <p>
                ANSYS CFX <StarRating score={4} />
              </p>
              <p>
                OpenFOAM <StarRating score={2} />
              </p>
              <h5>Finite element analysis</h5>
              <p>
                ANSYS Mechanical <StarRating score={2} />
              </p>
              <h5>1D modelling</h5>
              <p>
                Simcenter AMESim <StarRating score={4} />
              </p>
              <p>
                Simcenter FloMaster <StarRating score={3} />
              </p>
              <h5>Electromagnetics</h5>
              <p>
                ANSYS Maxwell <StarRating score={4} />
              </p>
              <h5>Multibody dynamics</h5>
              <p>
                MSC Adams <StarRating score={2} />
              </p>
              <p>
                ANSYS Motion <StarRating score={2} />
              </p>
              <h5>Process emulation</h5>
              <p>
                Emulate 3D <StarRating score={3} />
              </p>
              <h5>Computer aided design</h5>
              <p>
                SolidWorks <StarRating score={3} />
              </p>
              <p>
                OnShape <StarRating score={3} />
              </p>
              <p>
                NX <StarRating score={2} />
              </p>
              <h5>Programming</h5>
              <p>
                Matlab <StarRating score={4} />
              </p>
              <p>
                Python <StarRating score={3} />
              </p>
              <p>
                HTML / CSS / JavaScript <StarRating score={3} />
              </p>
              <p>
                C / C++ <StarRating score={2} />
              </p>
              <h2>Formal training</h2>

              <h5>Introduction to MATLAB</h5>
              <p>
                MathWorks
                <span style={{ float: "right" }}>Dec 2012</span>
              </p>

              <h5>Introduction to Computational Fluid Dynamics</h5>
              <p>
                NAFEMS
                <span style={{ float: "right" }}>Mar 2013</span>
              </p>
              <h5>Internal Auditor (IATF-16949)</h5>
              <p>
                Lloyds Register
                <span style={{ float: "right" }}>Nov 2015</span>
              </p>

              <h5>Introduction to Machining</h5>
              <p>
                AMRC
                <span style={{ float: "right" }}>Nov 2016</span>
              </p>

              <h5>Working Safely</h5>
              <p>
                IOSH
                <span style={{ float: "right" }}>Jan 2019</span>
              </p>

              <h5>Introduction to Simcenter AMESim</h5>
              <p>
                Siemens
                <span style={{ float: "right" }}>May 2019</span>
              </p>

              <h5>Adult Mental Health First Aid (Two Day)</h5>
              <p>
                MHFA England
                <span style={{ float: "right" }}>Nov 2021</span>
              </p>
              <h5>Lifecycle Management and Systems Engineering</h5>
              <p>
                Burge Hughes Walsh
                <span style={{ float: "right" }}>Mar 2023</span>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
}

export default About;
