import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Self from "../assets/self.jpeg";
import StarRating from "../components/StarRating.js";
import "../App.css";

function About() {
  return (
    <Container>
      <div className="about">
        <Container fluid>
        <Row>
          <Col md={{ order: "first", span: 4 }} className="header">
            <img src={Self} alt="Lauren's face" className="profile_large" />
            <h1>Hi, I'm Lauren</h1>
          </Col>
          <Col md={{ order: "last", span: 8 }}>
            <p>I'm passionate about solving complex engineering problems through simulation and analysis. My expertise spans CFD (Fluent, CFX), 1D modelling (AMESim, MATLAB, Python), and programming (MATLAB, Python) to optimise product performance, streamline workflows, and enhance analysis capabilities.</p>
            <p>With a strong background in leading teams, mentoring engineers, and developing cutting-edge methodologies, I ensure that simulation tools and techniques evolve to meet industry demands. I'm also enthusiastic about integrating different modelling approaches to create more robust and efficient solutions.</p>
            <p>Outside of my professional life, I love experimenting with new tools and technologies for personal projects. For example, I've designed and built a desktop CNC machine and regularly 3D print various components to solve practical problems around the home. These hands-on projects allow me to learn new skills, such as web development, and provide a creative outlet outside of work.</p>
            <p>I also enjoy staying active and spend time walking my dog, playing rugby with a local team, and competing in powerlifting events.</p>
            <h4>Key Areas of Expertise</h4>
            <p>Computational Fluid Dynamics & 1D System Modelling</p>
            <p>Simulation Automation & Process Optimization</p>
            <p>Technical Leadership & Delivering Training</p>
            <p>Advanced Programming for Engineering Applications</p>
          </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
}

export default About;
