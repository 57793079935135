import "../App.css";

function ExperienceCalc() {

  const startDate = Date.UTC(2012, 10, 8);
  const timeDiff = Date.now() - startDate;
  const dateDiff = new Date(timeDiff);

  return (

    Math.abs(dateDiff.getUTCFullYear() - 1970)
    
  );
  };
  
  export default ExperienceCalc;