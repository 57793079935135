import React from "react";
import Self from "../assets/self.jpeg";
import Container from "react-bootstrap/Container";
import ExperienceCalc from "../components/ExperienceCalc.js";

function Home() {
  return (
    <Container>
      <div className="home">
        <div className="header">
          <img src={Self} alt="Lauren's face" className="profile_small" />
          <h1>Laurengineer</h1>
          <p>Bringing science, code, and creativity together</p>
        </div>
        <p>Hi, I'm Lauren! I'm a Chartered Simulation and Analysis Engineer with <ExperienceCalc />+ years of professional experience in CFD and 1D system modelling. I love solving complex problems, optimising solutions, and making workflows more efficient - both at work and in my own projects. Whether it's designing and 3D printing custom parts for projects, learning new simulation techniques, or even creating this website, I'm always exploring new tools and techniques just for fun.</p>
        {/* <p>Please explore to see what I've been up to recently.</p> */}
        {/* <div className="recentPosts">
          PLACEHOLDER_FOR_RECENT_POSTS
        </div> */}
      </div>
    </Container>
  );
}

export default Home;
