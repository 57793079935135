import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import "../App.css";

function navbar() {
  return (
    <div className="navbar">
      <Navbar bg="light" expand="md" fixed="top">
        <Navbar.Brand href="/">Laurengineer</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/about">About</Nav.Link>
            {/* <Nav.Link href="/blog">Blog</Nav.Link> */}
            <Nav.Link href="/resume">Resum&#233;</Nav.Link>
            {/* <Nav.Link href="/contact">Contact</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
        {/* <Navbar.Collapse className="justify-content-end">
          <Button variant="primary" disabled>
            Log in
          </Button>{" "}
        </Navbar.Collapse> */}
      </Navbar>
    </div>
  );
}

export default navbar;
